import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import FlowyPlugin from '@hipsjs/flowy-vue';
import router from './router';
import axios from 'axios';
import { firestorePlugin } from 'vuefire';
import VueChartJs from 'vue-chartjs';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import OneSignalVue from 'onesignal-vue';
import Vue2Editor from 'vue2-editor';
import VuePapaParse from 'vue-papa-parse';

import { registerPlugin } from 'filepond';
import './assets/style.sass';

import '@hipsjs/flowy-vue/dist/lib/flowy-vue.css';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css';
import 'filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.css';
// vue-toastification
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileEncode);

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;
Vue.prototype.$vuetify = vuetify;

axios.defaults.baseURL = 'https://api.kingofthecurve.org/v2/';
// axios.defaults.baseURL = 'http://192.168.1.42:5001/';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.common['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('auth_token');
          localStorage.removeItem('auth_user');

          break;
      }
      return Promise.reject(error.response);
    }
  }
);

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
});

Vue.use(FlowyPlugin);
Vue.use(OneSignalVue);
Vue.use(VueChartJs);
Vue.use(firestorePlugin);
Vue.use(Vue2Editor);
Vue.use(VuePapaParse);

new Vue({
  vuetify,
  FlowyPlugin,
  VueChartJs,
  router,
  render: (h) => h(App)
}).$mount('#app');
